.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-image: url("./1010-Race.jpg");
    /* background-image: url("./1010race_old_map.jpg"); */
    background-size: cover;
    background-position: center;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: calc(10px + 2vmin);
    color: #000;
    padding-top: 2%;
}

.App-header .subtext {
    font-size: 10px;
}

.welcome-text {
    background: rgba(255, 255, 255, 0.8);
    padding: 10px;
    border: 1px solid rgba(0, 0, 0, 0.3);
    border-radius: 5px;
    width: 90%;
    /* margin-top: 10px; */
}

.App-link {
    color: #61dafb;
}

hr {
    color: rgba(0, 0, 0, 0.3);
}

#languageIconButton span {
    margin-right: 0;
    margin-left: 0;
}

.footer-link {
    color: #0288d1;
    text-decoration: none;
}
.fade-in {
    /* transition: opacity 1s ease-in-out; */
    opacity: 1;
    animation: fade 0.75s linear;
}

@keyframes fade {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
